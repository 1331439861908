<template>
  <div class="row">
    <institution-view-template :institution-data="school" :in-nebraska="true" :institution-type="'Nebraska School'"
                               :contact-users="contactUsers"/>
  </div>
  <div v-if="authUser.position.roleHierarchy === 1 || authUser.position.roleHierarchy === 6">
    <router-link :to="{name: 'EditSchool', params: {idSchool: idSchool}}">
      <button class="btn-primary button" id="btn-primary button">Edit This School</button>
    </router-link>
  </div>
  <div v-else>
    <hr/>
    <h6>
      <span style="font-weight: bold; color: black;">
        Contact the Educational Service Unit Coordinating Council to Edit/Update Contact Information
      </span>
    </h6>
  </div>
<!--  <div>-->
<!--    <OrganizationHierarchy-->
<!--        :country-code="'US'"-->
<!--        :state-code="'NE'"-->
<!--        :id-esu="school.idEsu"-->
<!--        :id-district="school.idDistrict"-->
<!--        :id-school="idSchool"-->
<!--    />-->
<!--  </div>-->
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import {useStore} from "vuex";
import InstitutionViewTemplate from "@/views/SuperUserViews/EducationalOrganizationManagement/InstitutionViewTemplate";
import OrganizationHierarchy from "@/components/UserOrganizationHierarchy/OrganizationHierarchy";
import {getSchool} from "@/Helpers/ApiCalls/SchoolAPICalls";


export default {
  name: "SchoolView",
    // eslint-disable-next-line vue/no-unused-components
  components: {OrganizationHierarchy, InstitutionViewTemplate},
  props: {
    idSchool: Number
  },
  setup(props, context) {
    const store = useStore();
    const authUser = computed(() => store.getters['auth/user']);
    const school = ref({});
    const contactUsers = ref([]);

    onBeforeMount(() => {
      getSchoolData();
      getUsers();
    })

    async function getSchoolData() {
      await axios.get(getSchool(props.idSchool), {
        params: {
          showUserCount: false
        }
      }).then((result) => {
            school.value = prepareSchoolData(result.data)
          })
    }

    async function getUsers() {
      let get_uri = API_URL + "/user/byOrgAndRole?_idOrganization=" + props.idSchool + "&_roleHierarchy=4";
      await axios.get(get_uri)
          .then((result) => {
            contactUsers.value = result.data;
          })
    }

    function prepareSchoolData(school) {
      return {
        name: school['schoolName'],
        primaryAddress: school['primarySchoolAddress'],
        phoneNumber: school['schoolPhoneNumber'],
        city: school['schoolCity'],
        cdn: school['schoolCdn'],
        idSchool: school['idSchool'],
        zipCode: school['schoolZipCode'],
        esu: school['schoolEsu'],
        district: school['schoolDistrict'],
        faxNumber: school['schoolFaxNumber'],
        secondaryAddress: school['secondarySchoolAddress'],
        idDistrict: school['idDistrict'],
        idEsu: school['idEsu']
      }
    }

    return {
      school,
      authUser,
      contactUsers
    }
  }
}
</script>

<style scoped>

</style>